import React from "react"
import Iframe from "react-iframe"

const Maps = () => (
  <Iframe
    url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.5533384231103!2d16.274470515109797!3d48.13813655910181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da61dfe6314b3%3A0x467ada0f7d3493d2!2sBreitenfurter+Str.+376%2C+1230+Wien!5e0!3m2!1sde!2sat!4v1564500634954!5m2!1sde!2sat"
    width="100%"
    height="450px"
    id="myId"
    display="initial"
    position="relative"
  />
)
export default Maps
