import React from "react"
import MainLayout from "../components/MainLayout"
import styled from "styled-components"
import Map from "../components/Map"
import SEO from "../components/SEO"
import ModalImage from "react-modal-image";
import lageplan_large from "../data/images/lageplan_large.png"

const Container = styled.div`
  background-color: white;
  padding: 0 4rem 6rem;
  @media (max-width: 900px) {
    padding: 2rem 1rem 6rem;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    text-align: center;
  }
`

const LeftColumn = styled.div``

const ModalImageContainer = styled.div`
  width: 22rem;
  @media (min-width: 770px) {
    padding-left: 2rem;
  }
`

export default ({ location: { pathname } }) => (
  <MainLayout>
    <SEO title="Ordination und Anfahrt" pathname={pathname} />
    <Container>
      <LeftColumn>
        <h1>Die Ordination</h1>
        <p>
          Die neuen, barrierefreien Ordinationsräumlichkeiten befinden sich
          unmittelbar beim Einkaufszentraum Riverside, Plaza Ebene 1. Stock,
          Ausgang West (barrierefreier Zugang).
        </p>
        <p>
          Über den Stiegenaufgang zwischen der Apotheke Liesing (Breitenfurter
          Straße 372) und der Garageneinfahrt des Einkaufszentrums Riverside ist
          die Ordination auch direkt von der Breitenfurter Straße aus zu
          erreichen.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ModalImageContainer>
        <ModalImage
          small={lageplan_large}
          large={lageplan_large}
          alt="Lageplan"
        />
          </ModalImageContainer>
        </div>
        <br/>
        <h3>Mit dem Auto:</h3>
        <p>
          Verbinden Sie Ihren Termin mit einem Besuch des Einkaufszentrums
          Riverside. Kunden des Einkaufszentrums parken für zwei Stunden gratis
          in der Riverside-Garage.
        </p>
        <br/>
        <h3>Öffentliche Anfahrt:</h3>
        <p>
          Der regionale Verkehrsknotenpunkt Bahnhof Liesing (u.a.
          Schnellbahnlinien S 1, 2, 3 und 4, Buslinien 60A, 62A, 64A und 66A)
          befindet sich ca. 10 Gehminuten entfernt.
          <br/>
          Die vom Bahnhof Liesing abfahrenden Buslinien 253, 254 und 259 halten direkt vor dem Stiegenaufgang zur Ordination (Haltestelle Alte Brauerei Liesing).
        </p>
      </LeftColumn>
      <br/>
      <Map />
    </Container>
  </MainLayout>
)
